import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/src/components/sections/highlight-products/HighlightProducts.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/sections/offers/Offers.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/Icon.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/arrow-clockwise.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/arrow-expand.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/arrow-forward.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/bouquests.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/calendar-checked.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/calendar.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/cancel-rounded.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/cancel.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/cart-checked.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/cart-empty.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/cart-filled.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/cart.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/catalog.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/check-rounded.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/checkbox-checked.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/checkbox.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/checkboxInter.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/chevron-down.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/chevron-left.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/clock-rounded.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/combined-offers.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/combo.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/community.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/copy.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/cross.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/delete.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/delivery.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/diameter.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/discount.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/done.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/edit.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/eye-off.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/eye.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/fast-delivery.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/fb.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/feedback-message.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/filter-settings.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/flower.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/form-of-pot.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/geo-location.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/google.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/heart.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/height.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/info.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/kashpo.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/left-feedback.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/logout.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/manufacturer.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/mastercard.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/maturity.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/minus.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/more.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/phone-contact.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/phone.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/photocamera.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/plus.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/potted.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/profile.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/progress.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/quality.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/reply.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/return.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/search.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/service-feedback.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/size.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/slider-arrow.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/sort.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/successful.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/support.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/telegram.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/truck.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/type-of-coating.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/viber.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/visa.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/volume.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/wait-payment.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/weight.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/whatsapp.svg");
import(/* webpackMode: "eager" */ "/app/src/components/shared/icon/svgs/width.svg");
import(/* webpackMode: "eager" */ "/app/src/layout/footer/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/page-layouts/main/Main.module.scss");
import(/* webpackMode: "eager" */ "/app/src/page-layouts/main/FeaturedCategories/FeaturedCategories.module.scss");
import(/* webpackMode: "eager" */ "/app/src/page-layouts/main/features/Features.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/shared/meta-links/MetaLinks.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/shared/meta-text/MetaText.module.scss");
import(/* webpackMode: "eager" */ "/app/src/layout/header-main/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/page-layouts/main/top/MainTop.tsx")